<template>
  <div>
    <el-dialog
      :z-index="1020"
      top="10vh"
      width="1216px"
      :visible="display"
      :rounded="true"
      :close-on-click-modal="false"
      destroy-on-close
      @close="$emit('on-close')"
    >
      <template #title>
        <h2>{{ $t('orders.catalogTitle') }} ({{ products.length }})</h2>
      </template>

      <div class="table-actions">
        <SearchBar
          :focus-on-mount="true"
          :search-text-ref="searchValue"
          :input-placeholder="$t('orders.SearchByProductName')"
          @on-supplier-text-change="onSearchProduct"
        />
        <div class="filters">
          <TableFilter
            button-filter
            class="catalog-modal-table-filter"
            :class="{ active: purchaseListFilter }"
            :filter-name="$t('orders.inPurchaseList')"
            :active="purchaseListFilter"
            :is-dark-theme="true"
            :is-value-weight-bold="true"
            @click="onPurchaseListClicked"
          />
          <DropdownTableFilter
            trigger="click"
            :clearable="false"
            :filter-name="$t('orders.catalogSortBy')"
            :filter-value="$t(`orders.catalogSortOptions.${sortOption}`)"
            :is-dark-theme="!isDefaultSortOption"
            :is-value-weight-bold="true"
            @on-choose-item="onOptionSelected"
          >
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="option in sortOptions"
                :key="option.value"
                :command="option.value"
                :class="{ 'dropdown-selected-item': sortOption === option.value }"
              >
                {{ option.label }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </DropdownTableFilter>
        </div>
      </div>

      <Table
        v-if="displayTable"
        custom-class="catalog-modal-table"
        :columns="tableColumns"
        :data="products"
        :border="true"
        rounded
        :cell-class="handleCellClass"
        :hover="false"
      >
        <template #cell-name="{ rowData: { name, sku, isRecommended, maxStockLevel, bom, available } }">
          <ProductCell
            :name="name"
            :sku="sku"
            :is-available="available"
            :is-recommended="isRecommended"
            :max-stock-level="maxStockLevel"
            :uom="getOrderBOM(bom)?.uom"
          />
        </template>

        <template #cell-lastOrderPrice="{ rowData: { lastOrderPrice } }">
          <span v-if="lastOrderPrice">{{ lastOrderPrice }}</span>
          <span v-else class="missing">{{ MISSING_DATA_TEXT }}</span>
        </template>

        <template #cell-maxStockLevel="{ rowData: { maxStockLevel } }">
          <div v-if="maxStockLevel">
            <p>{{ maxStockLevel }}</p>
          </div>
          <p v-else class="missing">{{ MISSING_DATA_TEXT }}</p>
        </template>

        <template #cell-quantityToAdd="{ rowData }">
          <OrderQuantityControls
            :row-data="inCartProductsMap[rowData.productId] ?? rowData"
            key-to-retrieve-quantity="quantityToAdd"
            :show-quantity-to-add="true"
            :is-available="rowData.available"
            :debounce="0"
            @on-quantity-save="updateItemQuantity"
          />
        </template>

        <template #cell-quantity="{ rowData: { productId } }">
          <div v-if="inCartProductsMap[productId] && inCartProductsMap[productId].quantity > 0">
            <p>{{ inCartProductsMap[productId].quantity }}</p>
          </div>
          <p v-else class="missing">{{ MISSING_DATA_TEXT }}</p>
        </template>
      </Table>

      <div class="d-flex flex-row-reverse">
        <el-button type="primary mt-5" size="small" :disabled="canSubmit" @click="$emit('on-submit')">
          {{ $t('orders.backToCart') }}{{ itemsWithQuantityState?.length ? ` (${itemsWithQuantityState.length})` : '' }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { debounce } from 'lodash';
import { ref, computed, reactive, getCurrentInstance } from 'vue';

import { Table, TableFilter, DropdownTableFilter, DIALOG_TYPES } from '@/modules/core';

import { catalogColumns } from './tableColumns';
import OrderQuantityControls from './OrderQuantityControls';
import ProductCell from './cellRenderers/ProductCell.vue';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';
import SearchBar from '@/modules/purchase-management/components/SearchBar.vue';
import { getOrderBOM } from '@/modules/purchase-management/purchaseManagement';
import { useAnalytics } from '../../../analytics/compositions/useAnalytics';
import { PUR_ANALYTICS_EVENT_TYPES } from '@/analytics/types/pur-analytics-types';

const CATALOG_SORT_OPTIONS = {
  LAST_ORDERED: 'lastOrdered',
  AMOUNT_OF_ORDERS: 'amountOfOrders',
  AB: 'ab',
};

const CATALOG_SORT_EVENT_TYPES = {
  [CATALOG_SORT_OPTIONS.LAST_ORDERED]: 'recent',
  [CATALOG_SORT_OPTIONS.AB]: 'abc',
  [CATALOG_SORT_OPTIONS.AMOUNT_OF_ORDERS]: 'quantity',
};

const DEBOUNCE_TIME = 500;

export default {
  components: { Table, SearchBar, OrderQuantityControls, ProductCell, TableFilter, DropdownTableFilter },
  props: {
    orderId: { type: String, required: true },
    display: { type: Boolean, default: false },
    filteredProducts: { type: Array, default: () => [] },
    selectedProducts: { type: Array, default: () => [] },
    itemsWithQuantityToAddCatalog: { type: Array, default: () => [] },
    recommendedProducts: { type: Array, default: () => [] },
  },
  emits: ['on-close', 'on-quantity-save-update', 'on-add-item-to-cart', 'on-submit', 'on-remove-item'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const displayTable = ref(true);
    const searchValue = ref('');
    const purchaseListFilter = ref(true);
    const sortOption = ref(CATALOG_SORT_OPTIONS.LAST_ORDERED);
    const { logEvent } = useAnalytics();

    const productsInCart = computed(() => props.selectedProducts);
    const recommendedProductsWithStockLevel = computed(() => props.recommendedProducts);
    const itemsWithQuantityState = computed(() => props.itemsWithQuantityToAddCatalog);
    const inCartProductsMap = reactive(
      productsInCart.value.reduce((newObj, product) => {
        if (!newObj[product.productId]) {
          newObj[product.productId] = {
            ...product,
            quantityToAdd: product.quantityToAdd,
          };
        }
        return newObj;
      }, {})
    );
    const isDefaultSortOption = computed(() => sortOption.value === CATALOG_SORT_OPTIONS.LAST_ORDERED);

    const sortOptions = Object.values(CATALOG_SORT_OPTIONS).map((value) => ({
      value,
      label: root.$t(`orders.catalogSortOptions.${value}`),
    }));

    const canSubmit = computed(() => !itemsWithQuantityState.value.length > 0);

    const products = computed(() => {
      const productsArray = props.filteredProducts.map((item) => ({ ...item, productId: item.id }));
      let filteredProducts = productsArray;

      if (searchValue.value.length) {
        filteredProducts = filteredProducts.filter(
          (product) => product.name.includes(searchValue.value) || product?.sku?.includes(searchValue.value)
        );
      }

      if (purchaseListFilter.value) {
        filteredProducts = filteredProducts.filter(({ isRecommended }) => isRecommended === true);
      }

      if (recommendedProductsWithStockLevel.value) {
        filteredProducts = filteredProducts.map((product) => {
          if (product.isRecommended === true) {
            const recommendedProduct = recommendedProductsWithStockLevel.value.find(
              ({ productId }) => productId === product.productId
            );

            return {
              ...product,
              maxStockLevel: recommendedProduct.maxStockLevel,
            };
          }
          return product;
        });
      }

      if (itemsWithQuantityState.value.length > 0) {
        itemsWithQuantityState.value.forEach((item) => {
          if (item.productId) {
            const rowData = filteredProducts.find(({ productId }) => productId === item.productId);
            inCartProductsMap[item.productId] = {
              ...rowData,
              quantity: undefined,
              quantityToAdd: item.quantity,
            };
          } else {
            const inCartProductsData = Object.values(inCartProductsMap);
            inCartProductsData.forEach((rowData) => {
              if (rowData.id === item.itemId) {
                inCartProductsMap[rowData.productId] = {
                  ...rowData,
                  quantityToAdd: item.quantity,
                };
              }
            });
          }
        });
      }

      if ([CATALOG_SORT_OPTIONS.LAST_ORDERED, CATALOG_SORT_OPTIONS.AMOUNT_OF_ORDERS].includes(sortOption.value)) {
        const productsWithLastOrderDate = [];
        const productsWithoutLastOrderDate = [];
        const productsWithNumberOfOrders = [];
        const productsWithoutNumberOfOrders = [];
        for (const product of filteredProducts) {
          if (product.lastOrderDate) {
            productsWithLastOrderDate.push(product);
          } else {
            productsWithoutLastOrderDate.push(product);
          }

          if (product.numberOfOrders) {
            productsWithNumberOfOrders.push(product);
          } else {
            productsWithoutNumberOfOrders.push(product);
          }
        }

        if (sortOption.value === CATALOG_SORT_OPTIONS.LAST_ORDERED) {
          productsWithLastOrderDate.sort(
            (a, b) => moment(b.lastOrderDate, 'DD.MM.YY') - moment(a.lastOrderDate, 'DD.MM.YY')
          );

          productsWithoutLastOrderDate.sort((a, b) => a.name.localeCompare(b.name));

          filteredProducts = [...productsWithLastOrderDate, ...productsWithoutLastOrderDate];
        } else {
          productsWithNumberOfOrders.sort((a, b) => b.numberOfOrders - a.numberOfOrders);
          productsWithoutNumberOfOrders.sort((a, b) => a.name.localeCompare(b.name));
          filteredProducts = [...productsWithNumberOfOrders, ...productsWithoutNumberOfOrders];
        }
      } else {
        filteredProducts.sort((a, b) => a.name.localeCompare(b.name));
      }

      return filteredProducts;
    });

    const updateItemQuantity = ({ rowData, quantity: quantityToAdd }) => {
      if (!inCartProductsMap[rowData.productId] || !inCartProductsMap[rowData.productId].quantity) {
        const quantity = Number(quantityToAdd);
        if (quantity > 0) {
          emit('on-add-item-to-cart', { ...rowData, quantity });
        } else {
          delete inCartProductsMap[rowData.productId];
          emit('on-remove-item', { item: rowData, fromCreate: true });
        }
      } else {
        const quantity = Number(quantityToAdd);
        if (quantity > 0) {
          emit('on-quantity-save-update', {
            ...rowData,
            quantity,
          });
        } else {
          inCartProductsMap[rowData.productId].quantityToAdd = 0;
          emit('on-remove-item', { item: rowData });
        }
      }
    };

    const toggleTable = () => {
      displayTable.value = false;
      displayTable.value = true;
    };

    const onPurchaseListClicked = () => {
      if (purchaseListFilter.value) {
        logEvent(PUR_ANALYTICS_EVENT_TYPES.ORDER_PROCESS.CATALOG_FILER, {
          orderId: props.orderId,
          filter: 'all items',
        });
      } else {
        logEvent(PUR_ANALYTICS_EVENT_TYPES.ORDER_PROCESS.CATALOG_FILER, {
          orderId: props.orderId,
          filter: 'purchase list',
        });
      }
      purchaseListFilter.value = !purchaseListFilter.value;
    };

    const onOptionSelected = (value) => {
      logEvent(PUR_ANALYTICS_EVENT_TYPES.ORDER_PROCESS.CATALOG_FILER, {
        orderId: props.orderId,
        sort: CATALOG_SORT_EVENT_TYPES[value],
      });
      sortOption.value = value;
    };

    const handleCellClass = (rowIndex) => {
      if (products.value[rowIndex]?.available === false) {
        return 'catalog-disabled-col';
      }
    };

    const debouncedLogSearchProductEvent = debounce(() => {
      logEvent(PUR_ANALYTICS_EVENT_TYPES.ORDER_PROCESS.CATALOG_FILER, {
        orderId: props.orderId,
        'free text': searchValue.value ?? 'empty text',
      });
    }, DEBOUNCE_TIME);

    const onSearchProduct = (value) => {
      searchValue.value = value;
      debouncedLogSearchProductEvent();
    };

    return {
      DIALOG_TYPES,
      MISSING_DATA_TEXT,
      products,
      canSubmit,
      sortOption,
      sortOptions,
      searchValue,
      toggleTable,
      displayTable,
      inCartProductsMap,
      purchaseListFilter,
      isDefaultSortOption,
      onOptionSelected,
      updateItemQuantity,
      onPurchaseListClicked,
      tableColumns: catalogColumns(),
      getOrderBOM,
      handleCellClass,
      onSearchProduct,
      itemsWithQuantityState,
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep .table-responsive {
  height: 62vh !important;
}

::v-deep .el-input__suffix {
  height: 10px !important;
  margin: 0 !important;
  padding: 0 !important;
}

::v-deep .el-dialog__close {
  font-weight: bold;
  color: black !important;
  &:hover {
    background: #ecf0f3;
    color: black !important;
  }
}

::v-deep .el-input__inner {
  height: 2rem !important;
}

::v-deep .el-dialog {
  border-radius: 0.5rem;
}

::v-deep .el-dialog__headerbtn {
  font-size: 22px;
  top: 19px;
}
.table-actions {
  display: flex;
  gap: 8px;
}
.filters {
  width: fit-content;
  margin-bottom: 1rem;
  display: flex;
  gap: 8px;
}
.dropdown-selected-item {
  color: #3661dd;
  background-color: #f5f7fa;
}
</style>
<style lang="scss">
.catalog-modal-table {
  tr {
    &:hover {
      .order-quantity-product-latest-order-data {
        display: block !important;
      }
    }
  }
}

.catalog-modal-table-filter {
  &.active {
    .filter-name {
      font-weight: 500 !important;
    }
  }
}
.catalog-disabled-col * {
  color: #9295a5;
}
</style>
